const keys = {
  //pili
  // googleClientId: '879850649652-38d8r7c1qjtdok66iepctpoqe1qqu8h0.apps.googleusercontent.com',

  //loli
  googleClientId:
    "614914933397-emgdvqjsau1ii53uj94ggt7h1d58mcmq.apps.googleusercontent.com",

  // JOSE client id
  // googleClientId:
  //   "43374848214-hl38qlsqb3a3splnoljs4shmm900d0ep.apps.googleusercontent.com",
};

export default keys;
